@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('./fonts/MYRIADPRO-REGULAR.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('./fonts/MYRIADPRO-COND.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('./fonts/MYRIADPRO-CONDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('./fonts/MyriadPro-Light.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('./fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('./fonts/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('./fonts/MYRIADPRO-BOLDCOND.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('./fonts/MYRIADPRO-BOLD.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('./fonts/MYRIADPRO-BOLDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('./fonts/MYRIADPRO-BOLDCONDIT.woff') format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Myriad Pro Regular" !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none !important;
}

*, *::after, *::before {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}

.css-unb3gv-MuiStack-root>:not(style)~:not(style) {
  margin-top: 0 !important;
}

.teache-course:hover ul.teache-course-info-list li span svg path {
  fill: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #AA233B; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #AA233B; 
}

/* .swiper {
  width: 100%;
  height: 100%;
} */

.why-us .css-bel3zu{
  width: auto !important;
  height: auto !important;
}

.why-us .autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
}

.why-us .autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: #fff;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.why-us .swiper-pagination-bullet-active {
  background-color: #fff !important;
  width: 20px;
  border-radius: 4px;
}

.why-us .swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1;
}

/* .our-teachers-info .swiper-slide {
  width: 100px !important;
} */

.our-teachers-info .swiper-pagination-bullet-active {
  background-color: #AA233B;
}

.our-teachers-info  .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

.our-teachers-info .swiper{
  padding-bottom: 60px;
}

@media only screen and (min-width: 1000px) {
  .our-teachers-info  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
  }
}

/* ///////////////////////////////////////////////////// */
.carousel {
  background: #fafafa;
  margin-bottom: 40px;
}

.carousel-nav {
  margin-bottom: 40px;
}

.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: carousel-cell;
}

.carousel-cell-1 {
  width: 20%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: carousel-cell;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel-cell-1:before {
  display: block;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel-nav .carousel-cell {
  height: 80px;
  width: 100%;
}

.carousel-nav .carousel-cell:before {
  font-size: 50px;
  line-height: 80px;
}

.carousel-nav .carousel-cell-1.is-nav-selected {
  background: #ed2;
}

.students-result .swiper {
  height: 100%;
}

.students-result .swiper-pagination-bullets {
  display: none;
}

@media only screen and (min-width: 700px) {
  .students-result .swiper {
    height: 400px;
  }
}